import axios from 'axios'


function refreshPage() {
    window.location.reload(true);
  }

export const getSocios = async (limit) => {
    try{
    const res= await fetch(`https://dev-socios-cantera.herokuapp.com/api/socio?maximo=${limit}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            "Content-Type": "application/json",
        },
    });
    const dataSocios = await res.json();
    return dataSocios
    }catch(err){
        console.log(err)
    }
}
export const getSocioById = async (id) => {
    try{
    const res= await fetch(`https://dev-socios-cantera.herokuapp.com/api/socio/${id}`, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            "Content-Type": "application/json",
        },
    });
    const dataSocio = await res.json();
    return dataSocio
    }catch(err){
        console.log(err)
    }
}

export const loginAdm = async (username, password) => {
    const response = await axios.post(
        'https://dev-socios-cantera.herokuapp.com/api/login', {
        'username': username,
        'password': password
        // 'username': 'defaultUser',
        // 'password': 'cam.piedra.2023'
    }, {
        headers: {
            'Content-Type': 'application/json',
        }
    }).then(res => (localStorage.setItem('token', res.data.token), refreshPage()))

}

export const logOut = () => {
    localStorage.removeItem('token')
    refreshPage()
}
// localStorage.setItem('token', res.token)
export const agregarSocio = async (razonSocial, residencia, domicilio, telefono, codigoPostal, localidad, email, contacto, web, ubicacion) => {
    await axios.post(
        'https://dev-socios-cantera.herokuapp.com/api/socio', {
        razon: razonSocial,
        residencia: residencia,
        domicilio: domicilio,
        telefono: telefono,
        cp: codigoPostal,
        localidad: localidad,
        emails: [email],
        contacto: contacto,
        web: web,
        ubicacionG: ubicacion,
        comoLlegar: '',
        masInfo: ''

    }, {
        headers: {
            Authorization: localStorage.getItem("token"),
            'Content-Type': 'application/json',
        }
    }).then(refreshPage);
}

export const editarSocio = async ( id ,razonSocial, residencia, domicilio, telefono, codigoPostal, localidad, email, contacto, web, ubicacion) => {
    const baseUrl = `https://dev-socios-cantera.herokuapp.com/api/socio/${id}`
        await axios.put(
        baseUrl, {
        razon: razonSocial,
        residencia: residencia,
        domicilio: domicilio,
        telefono: telefono,
        cp: codigoPostal,
        localidad: localidad,
        emails: email,
        contacto: contacto,
        web: web,
        ubicacionG: ubicacion,
        comoLlegar: '',
        masInfo: ''

    }, {
        headers: {
            Authorization: localStorage.getItem("token"),
            'Content-Type': 'application/json',
        }
    })
    .then(refreshPage);
}

export const borrarSocio = async ( id ) => {
    const baseUrl = `https://dev-socios-cantera.herokuapp.com/api/socio/${id}`
     await axios.delete(
        baseUrl ,{
        headers: {
            Authorization: localStorage.getItem("token"),
            'Content-Type': 'application/json',
        }
    }).then(refreshPage);
}

export const permisos = () =>{
    return !!localStorage.getItem("token")
}
export default loginAdm