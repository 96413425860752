import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material'
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import steper1 from '../assets/steper.jpeg'
import steper2 from '../assets/steper2.jpeg'
import steper3 from '../assets/steper3.jpeg'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    src: steper1
  },
  {
    src: steper2
  },
  {
    src: steper3

  },
];

function SwipeableTextMobileStepper() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box md={8} xs={12}>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 50,
          pl: 2,
          bgcolor: 'background.default',
          position: 'relative', // Agregar la propiedad position
          zIndex: 1, // Establecer el valor de zIndex en 1
        }}
      >
      </Paper>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        style={{ display: 'flex', justifyContent: 'center' }} >
        {images.map((step, index) => (
          <Grid item key={step.src + index} md={8} xs={10} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  width:'1443px',
                  height:'521px',
                  display: 'flex',
                  overflow: 'hidden',
                }}
                src={step.src}
              />
            ) : null}
          </Grid>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="center"
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        activeStep={activeStep}
        nextButton={undefined} backButton={undefined} />
    </Box>
  );
}

export default SwipeableTextMobileStepper;
