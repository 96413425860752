import React, { useState } from 'react'
import { Grid } from '@mui/material'
import Steper from '../Steper'
import startSection from '../../assets/startSection.jpeg'
import cardSocio1 from '../../assets/cardSocio1.jpeg';
import cardSocio2 from '../../assets/cardSocio2.jpeg';
import cardSocio3 from '../../assets/cardSocio3.jpeg';

import './StartSection.css'

const StartSection = () => {
  const [readMore, setReadMore] = useState(false)
  return (
    <>
      <Grid>
        <Steper />
      </Grid>
      <Grid container className='StartSectionContainer' xs={12} md={12}>
        <Grid item xs={12} md={4}>
          <Grid item xs={12} md={6}>
            <p className='StartSectionTitle'>
              La Cámara de la Piedra
            </p>
          </Grid>
          <Grid item xs={12} md={10}>
            <p className='StartSectionIntroduction'>La piedra es la segunda materia prima más consumida por el Ser
              Humano, cuidarla y saber administrarla es también nuestra misión y responsabilidad</p>
          </Grid>
          <Grid item xs={12} md={10}>
            <p className='StartSectionSubTitle'>
              Sus objetivos son:
            </p>
          </Grid>
          <Grid item xs={12} md={10}>
            <ul>
              <li className='StartSectionText'>
                Defender los intereses comunes industriales mineros de sus asociados.
              </li>
              <li className='StartSectionText'>
                Fomentar el desarrollo y perfeccionamiento de la Industria de la piedra
                en la Provincia de Buenos Aires, orientando su acción a una mayor
                productividad y bienestar social.
              </li>
              <li className='StartSectionText'>
                Gestionar y auspiciar ante las autoridades públicas e instituciones
                privadas, toda medida, acto o resolución que implique una mejora
                técnica o económica para las actividades de sus asociados.
              </li>
              <li className='StartSectionText'>
                Prestar su más amplio asesoramiento y colaboración a los poderes
                públicos en todos los problemas relacionados con la industria de la
                piedra y representar y sostener los derechos e intereses comunes de sus asociados.
              </li>
              <li className='StartSectionText'>
                Integrar Federaciones y cooperar con otras asociaciones o entidades
                que persigan los mismos fines.
                Asesorar a los asociados sobre los distintos aspectos de su actuación y
                velar para que las relaciones entre los mismos se desenvuelvan dentro
                de la mayor armonía y respeto mutuo.
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} md={10}>
            {
              readMore &&
              <Grid item md={10}>
                <ul>
                  <li className='StartSectionText'>
                    Asesorar a los asociados sobre los distintos
                    aspectos de su actuación y velar para que las
                    relaciones entre los mismos se desenvuelvan dentro
                    de la mayor armonía y respeto mutuo.
                  </li>
                  <li className='StartSectionText'>
                    Estudiar y proponer soluciones a todos los
                    problemas de orden técnico, económico y social
                    que afectan a la Industria de la piedra.
                  </li>
                  <li className='StartSectionText'>
                    Proveer a sus asociados de un lugar de reunión para
                    tratar asuntos comunes y la información técnica y
                    legal que les sea necesaria.
                  </li>
                  <li className='StartSectionText'>
                    Designar delegados a las comisiones paritarias para
                    la discusión de los convenios laborales.
                  </li>
                </ul>
                <span className='btnReadMore' onClick={() => { setReadMore(false) }}>Leer menos...</span>
              </Grid>
            }
            <Grid style={{marginBottom:'25px'}} >
              {
                !readMore &&
                <span className='btnReadMore' onClick={() => { setReadMore(true) }}>Leer mas...</span>

              }
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid item xs={12} md={8}>
            <ul>
              <li className='StartSectionText'>
                Estudiar y proponer soluciones a todos los problemas de orden técnico, económico y social que afectan a la Industria de la piedra.
              </li>
              <li className='StartSectionText'>
                Proveer a sus asociados de un lugar de reunión para tratar asuntos comunes y la información técnica y legal que les sea necesaria.
              </li>
              <li className='StartSectionText'>
                Designar delegados a las comisiones paritarias para la discusión de los convenios laborales.
              </li>
            </ul>
            <Grid className='imgStarSectionContainer'>
              <img src={startSection} className='imgStarSection' />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid md={12} className='cardSocioSeccion'>
        <p className='cardSocioTitle'>Participaciones de la Camara</p>
        <Grid md={12} className="cardSocioContainer ">
          <Grid item className='cardSocioContainerIndividual' style={{ padding: '3%', paddingTop: '0%' }}>

            <Grid className='cardSocio'>
              <Grid item xs={12} md={12}>
                <img src={cardSocio1} className='cardSocioImg1' />
              </Grid>
              <Grid item>
                <p className='cardSocioYear'>2013</p>
              </Grid>
              <Grid item>
                <p className='cardSocioText'>IX Exposición Internacional de Minería, ARMINERA, Ciudad Autónoma de Buenos Aires, Argentina. <br />
                  Expo Rural de Olavarría, Pcia. Bs. As., Argentina
                  Expo Rural de Olavarría, Pcia. Bs. As., Argentina</p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className='cardSocioContainerIndividual' style={{ padding: '3%', paddingTop: '0%' }}>
            <Grid item className='cardSocio'>
              <img src={cardSocio2} className='cardSocioImg1' />
              <Grid item>
                <p className='cardSocioYear'>2012</p>
              </Grid>
              <Grid item>
                <p className='cardSocioText'>III Congreso de Áridos, Cáceres, España
                  XVI Congreso de Vialidad y transporte, Córdoba, Argentina
                  Expo Rural de Olavarría, Pcia. Bs. As., Argentina</p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className='cardSocioContainerIndividual' style={{ padding: '3%', paddingTop: '0%' }}>

            <Grid className='cardSocio'>
              <Grid item xs={12} md={5}>
                <img src={cardSocio3} className='cardSocioImg1' />
              </Grid>
              <Grid item>
                <p className='cardSocioYear'>2011</p>
              </Grid>
              <Grid item>
                <p className='cardSocioText'>IX Jornadas de Iberoamericanas de materiales para la construcción, Quito, Ecuador
                  Asamblea Anual de la Federación Iberoamericana de Productores de Áridos, Quito, Ecuador
                  Expo Rural de Olavarría, Pcia. Bs. As., Argentina
                  Asamblea Anual de la Federación Iberoamericana de Productores de Áridos, Quito, Ecuador
                  Expo Rural de Olavarría, Pcia. Bs. As., Argentina</p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className='cardSocioContainerIndividual' style={{padding:'3%', paddingTop:'0%'}}>
          <Grid className='cardSocio'>
            <Grid item xs={12} md={5}>
              <img src={cardSocio3} className='cardSocioImg1' />
            </Grid>
            <Grid item>
              <p className='cardSocioYear'>2010</p>
            </Grid>
            <Grid item>
              <p className='cardSocioText'>III Congreso de Educación de la Provincia de Buenos Aires- Educación, empresa y Sociedad, Olavarría, Pcia. de Bs. As.
                VIII Jornadas de Iberoamericanas de materiales para la construcción, Lima, Perú
                Asamblea Anual de la Federación Iberoamericana de Productores de Áridos, Lima, Perú.
                Foro Mujeres Trabajando en la Minería, Hotel Emperador, Ciudad Autonoma de Buenos Aires, Argentina.
                Expo Rural de Olavarría, Pcia. Bs. As., Argentina</p>
            </Grid>
          </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default StartSection